import dayjs from 'dayjs';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { ActivityRange } from 'types/Filters';

interface KeywordsFilter {
  excludeKeywords: string[];
  includeKeywords: string[];
  includeLanguages: string[];
}

export type SocialTrackingStore = {
  activity: {
    time: {
      from: string | null;
      to: string | null;
    };
    range: ActivityRange | null;
  };
  keywords: KeywordsFilter;
  setKeywords: (values: KeywordsFilter) => void;
  lastVisitedProjectId: string | null;
  setLastVisitedProjectId: (id: string | null) => void;
  isOrganicSelected: boolean;
  setIsOrganicSelected: (value: boolean) => void;
  kolsGroupsIds: string[];
  setKolsGroupsIds: (ids: string[]) => void;
  setActivityRange: (
    range: ActivityRange | null,
    from: string | null,
    to: string | null,
  ) => void;

  chartClickedActiveRange: { from: string; to: string } | null;
  setChartClickedActiveRange: (
    key: { from: string; to: string } | null,
  ) => void;
  selectedKol: string | null;
  setSelectedKol: (kol: string | null) => void;
  resetAllFilters: () => void;
  hasSeenProject: boolean;
  setHasSeenProject: (value: boolean) => void;
};

const prepareStore: StateCreator<SocialTrackingStore> = (set) => ({
  lastVisitedProjectId: null,
  setLastVisitedProjectId: (id) => set({ lastVisitedProjectId: id }),
  isOrganicSelected: true,
  setIsOrganicSelected: (value) => set({ isOrganicSelected: value }),
  kolsGroupsIds: [],
  setKolsGroupsIds: (ids) => set({ kolsGroupsIds: ids }),
  activity: {
    time: {
      from: dayjs().subtract(7, 'day').toISOString(),
      to: dayjs().toISOString(),
    },
    range: '7d',
  },
  setActivityRange: (range, from, to) =>
    set(() => ({
      activity: {
        time: {
          from,
          to,
        },
        range,
      },
    })),
  keywords: {
    includeKeywords: [],
    excludeKeywords: [],
    includeLanguages: [],
  },
  setKeywords: (values) => set({ keywords: values }),
  resetAllFilters: () =>
    set({
      kolsGroupsIds: [],
      isOrganicSelected: true,
      keywords: {
        includeKeywords: [],
        excludeKeywords: [],
        includeLanguages: [],
      },
      activity: {
        time: {
          from: dayjs().subtract(7, 'day').toISOString(),
          to: dayjs().toISOString(),
        },
        range: '7d',
      },
    }),
  chartClickedActiveRange: null,
  setChartClickedActiveRange: (key) =>
    set(() => ({ chartClickedActiveRange: key, selectedKol: null })),
  selectedKol: null,
  setSelectedKol: (kol) =>
    set(() => ({ selectedKol: kol, chartClickedActiveRange: null })),
  hasSeenProject: false,
  setHasSeenProject: (value) => set(() => ({ hasSeenProject: value })),
});

export const useSocialTrackingStore = create(
  persist(prepareStore, {
    name: 'social-tracking-store',
    version: 1.0,
    partialize: (state) => ({
      activity: state.activity,
      hasSeenProject: state.hasSeenProject,
      kolsGroupsIds: state.kolsGroupsIds,
      lastVisitedProjectId: state.lastVisitedProjectId,
    }),
  }),
);
