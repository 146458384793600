import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from 'src/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const CnTooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border border-ds-border-tertiary bg-ds-primary px-3 py-1.5 text-xs text-ds-text-secondary shadow-lg animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
      'font-sans',
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip = ({
  children,
  content,
  sideOffset,
  ...props
}: React.ComponentProps<typeof TooltipContent> & {
  content: React.ReactNode;
}) => {
  if (!content) return <>{children}</>;

  return (
    <TooltipProvider>
      <CnTooltip delayDuration={0}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent sideOffset={sideOffset} {...props}>
          {content}
        </TooltipContent>
      </CnTooltip>
    </TooltipProvider>
  );
};

export { Tooltip, TooltipContent, TooltipTrigger };
